import * as React from 'react';
import classNames from 'classnames';
import { IResponsiveRepeaterSkinProps } from '../ResponsiveRepeater.types';
import ResponsiveContainer from '../../../thunderbolt-core-components/ResponsiveContainer/viewer/ResponsiveContainer';
import { getDataAttributes } from '../../../core/commons/utils';

const ResponsiveRepeater: React.FC<IResponsiveRepeaterSkinProps> = props => {
  const { id, responsiveContainerProps, items, children, classes, className } =
    props;

  const responsiveContainerChildren = React.useCallback(
    () =>
      items.map(itemId => (
        <React.Fragment key={itemId}>{children(itemId)}</React.Fragment>
      )),
    [children, items],
  );

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={classNames(className, classes.bg)}
    >
      <ResponsiveContainer {...responsiveContainerProps}>
        {responsiveContainerChildren}
      </ResponsiveContainer>
    </div>
  );
};

export default ResponsiveRepeater;
