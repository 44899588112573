import Repeater_ResponsiveComponent from '@wix/thunderbolt-elements/src/components/Repeater/viewer/skinComps/Responsive/Responsive.skin';


const Repeater_Responsive = {
  component: Repeater_ResponsiveComponent
};


export const components = {
  ['Repeater_Responsive']: Repeater_Responsive
};


// temporary export
export const version = "1.0.0"
